import React, { useState } from 'react';
import MainImage from '../../images/portfolio/Creaza/AlfabetPlaneten/thumbnail.jpg';
import OmarDancing from '../../images/portfolio/Creaza/AlfabetPlaneten/omar_dancing.png';
import OmarSmiling from '../../images/portfolio/Creaza/AlfabetPlaneten/omar_smiling.png';
import OmarPlaying from '../../images/portfolio/Creaza/AlfabetPlaneten/omar_playing.png';
import PortfolioPage from '../../components/PortfolioPage';
import G_BG from '../../images/portfolio/Creaza/AlfabetPlaneten/G_BG.png';
import G_BG_COLORED from '../../images/portfolio/Creaza/AlfabetPlaneten/G_BG_COLORED.png';
import OmarMal from '../../images/portfolio/Creaza/AlfabetPlaneten/Omar-mal.jpg';

const PortfolioCreaza = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage img={MainImage} url="https://player.vimeo.com/video/786521681?h=da4c029e23"  title="Alfabetplaneten" description="Creaza ville skape en helt ny verden for 1.-4. klassinger i sitt læreverktøy, cartoonist. Verdenen heter Alfabetplaneten og inneholder gøyale karakterer, props og bakgrunner tilknyttet hver sin bokstav. Disse kan elevene bruke til å lage digitale fortellinger, tegneserier, presentasjoner og filmer.
    Til sammen produserte vi over 2000 vektorillustrasoner sammen med to animerte reklamefilmer for prosjektet. 
    (2022)">
         <div className=" mt-32 lg:px-0">
            <div className="flex max-w-xl justify-center">
              <img src={OmarPlaying}  />
            </div>
            <div className="flex flex-wrap mt-16 justify-center mb-16">
              <img src={G_BG} className="max-w-md mb-8 px-2" />
              <img src={G_BG_COLORED} className="max-w-md px-2" />
            </div>
            <div className="flex justify-center">
             <img src={OmarMal} />
            </div>
            
          </div>
    </PortfolioPage>

}

export default PortfolioCreaza;